@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Catamaran&family=Fira+Code&family=M+PLUS+1&family=Montserrat:wght@400;600&family=Nunito&family=Oxygen&family=Titillium+Web&display=swap');

@layer base {
  html {
    font-family: fira code
  }

  .heading {
    font-family: fira code
  }



}

.about-list-item {
  @apply text-light-purple w-2/3 flex flex-row gap-2 hover:text-green
}
.about-p{
  @apply text-light-purple pt-4 pb-4 m-0 text-sm md:text-lg
}

.featured-project{
  @apply text-green md:text-sm mb-0.5
}

.featured-project-title {
  @apply text-light-purple md:text-3xl hover:text-green z-30
}
.featured-project-description {
  @apply text-light-purple bg-ming md:text-lg md:w-[28em] p-4 md:p-6 mt-24 md:mt-8 mb-0 z-30 rounded-md shadow-md hover:shadow-2xl   
}
/* transition peer-hover/video:opacity-5 peer-hover/video:duration-300 */
.featured-project-ul {
  @apply flex flex-row gap-4 md:w-[30em] z-30 pt-10 pb-10 md:pt-0 
}
/* .featured-extra{
  @apply relative -bottom-12
} */

.side-panel-item{
  @apply text-light-purple transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 hover:text-green duration-300 pt-2 ml-2
}

::-webkit-scrollbar {
  height: 2em
}
::-webkit-scrollbar-button {
  background: #ccc;
  height: 12px;
}
::-webkit-scrollbar-track-piece {
  background: #888
}
::-webkit-scrollbar-thumb {
  background: #eee
}
